import React from 'react';
import './messenges.scss';

const Messengers = ({ cls, handleChange, active, prefix }) => {
	return (
		<div className={`messengers ${cls ? cls : ''}`}>
			<div className="messengers__text">Выберете способ связи</div>
			<div className="messengers__list">
				<div className='messengers__item'>
					<input type="radio" id={`messenger-call${prefix || ''}`} value='call' checked={active === 'call'} onChange={(e) => handleChange(e)}/>
					<label htmlFor={`messenger-call${prefix || ''}`}>Телефон</label>
				</div>
				<div className='messengers__item'>
					<input type="radio" id={`messenger-whatsapp${prefix || ''}`} value='whatsapp' checked={active === 'whatsapp'} onChange={(e) => handleChange(e)}/>
					<label htmlFor={`messenger-whatsapp${prefix || ''}`}>Whatsapp</label>
				</div>
				<div className='messengers__item'>
					<input type="radio" id={`messenger-telegram${prefix || ''}`} value='telegram' checked={active === 'telegram'} onChange={(e) => handleChange(e)}/>
					<label htmlFor={`messenger-telegram${prefix || ''}`}>Telegram</label>
				</div>
			</div>
		</div>
	);
};

export default Messengers;
